import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import SEO from '../components/SEO'
import Layout from '../components/Layout'

export default function ProjectDetails({ data }) {
    const { html } = data.markdownRemark
    const { title, thumb } = data.markdownRemark.frontmatter
    return (
        <Layout>
            <SEO title={title} />
            <div>
                <p>{title}</p>
                <GatsbyImage image={thumb.childImageSharp.gatsbyImageData} alt={title} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </Layout>

    )
}

export const query = graphql`
    query ProjectDetails($slug: String!) {
        markdownRemark(frontmatter: {slug: {eq: $slug}}) {
            html
            frontmatter {
                date
                title
                published
                thumb {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;